<template>
  <!-- 活动信息收集 -->
  <div class="activity">
    <div class="activity-bg" v-if="!state.noShow">
      <div class="title">
        {{ state.taskType && redirectMap[state.taskType].title }}
      </div>
    </div>
    <div class="content">
      <a-spin :spinning="state.loading">
      <div class="detail" v-if="!state.noShow">
        <div class="title">{{ state.info.taskName }}</div>
        <div class="time">
          <ClockCircleOutlined />
          {{ dateFormat(state.info.startTime) }}
          ~
          {{ dateFormat(state.info.endTime) }}
        </div>
      </div>
      <a-form
        ref="formRef"
        :model="formState"
        :labelCol="{ span: layout === 'horizontal' ? 4 : 24 }"
        :wrapperCol="{ span: layout === 'horizontal' ? 20 : 24 }"
        :rules="rules"
        :layout="layout"
      >
        <template v-for="item in state.fieldList" :key="item.fieldKey">
          <!-- 输入 -->
          <a-form-item
            v-if="item.fieldType === 1"
            :label="item.fieldName"
            :name="item.fieldKey"
          >
            <a-input
              allowClear
              v-model:value="formState[item.fieldKey]"
              :maxlength="50"
              :placeholder="
                item.tipContent ||
                $t('LB_QA_PleaseEnter') +
                item.fieldName +
                $t('charactersOrLess', [50])
              "
            />
          </a-form-item>
          <!-- 下拉 -->
          <a-form-item
            v-if="item.fieldType === 2"
            :label="item.fieldName"
            :name="item.fieldKey"
          >
            <!-- 省市区 === 城市 -->
            <a-cascader
              v-if="item.fieldKey === 'city2'"
              :changeOnSelect="true"
              allowClear
              :field-names="{
                label: 'name',
                value: 'name',
                children: 'list'
              }"
              v-model:value="formState[item.fieldKey]"
              :options="state.cityList"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
            />
            <!-- 国家 -->
            <a-select
              v-else-if="item.fieldKey === 'country'"
              allowClear
              v-model:value="formState[item.fieldKey]"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
            >
              <a-select-option
                v-for="op in state.countryList"
                :key="op.countryId"
                :value="op.countryName"
              >{{ op.countryName }}</a-select-option>
            </a-select>
            <!-- 岗位 -->
            <a-select
              v-else-if="item.fieldKey === 'position'"
              allowClear
              v-model:value="formState[item.fieldKey]"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
            >
              <a-select-option
                v-for="op in state.positionList"
                :key="op.positionId"
                :value="op.positionId"
              >{{ op.positionName }}</a-select-option>
            </a-select>
            <a-select
              v-else
              allowClear
              v-model:value="formState[item.fieldKey]"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
              :labelInValue="item.defaultField === 2 ? true : false"
              :options="systemFieldOption[item.fieldKey] || item.fieldOptions"
            />
          </a-form-item>
          <!-- 数字 -->
          <a-form-item
            v-if="item.fieldType === 4"
            :label="item.fieldName"
            :name="item.fieldKey"
          >
            <a-input-number
              allowClear
              v-model:value="formState[item.fieldKey]"
              :placeholder="item.tipContent || `${$t('LB_QA_PleaseEnter')}${item.fieldName}`"
              :precision="item.len || 0"
              style="width: 100%"
            />
          </a-form-item>
          <!-- 日期 -->
          <a-form-item
            v-if="item.fieldType === 5"
            :label="item.fieldName"
            :name="item.fieldKey"
          >
            <a-date-picker
              :getCalendarContainer="triggerNode => triggerNode.parentNode"
              valueFormat="YYYY-MM-DD"
              v-model:value="formState[item.fieldKey]"
              allowClear
              style="width: 100%"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
            />
          </a-form-item>
          <!-- 文本 -->
          <a-form-item
            v-if="item.fieldType === 6"
            :label="item.fieldName"
            :name="item.fieldKey"
          >
            <a-textarea
              v-model:value="formState[item.fieldKey]"
              :placeholder="
                item.tipContent ||
                $t('LB_QA_PleaseEnter') +
                item.fieldName +
                $t('charactersOrLess', [500])
              "
              :maxlength="500"
              showCount
              :rows="4"
              allowClear
            />
          </a-form-item>
          <!-- 时间 -->
          <a-form-item
            v-if="item.fieldType === 7"
            :label="item.fieldName"
            :name="item.fieldKey"
          >
            <a-date-picker
              :getCalendarContainer="triggerNode => triggerNode.parentNode"
              valueFormat="YYYY-MM-DD HH:mm"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              v-model:value="formState[item.fieldKey]"
              allowClear
              style="width: 100%"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
            />
          </a-form-item>
          <!-- 树 -->
          <a-form-item
            v-if="item.fieldType === 8"
            :label="item.fieldName"
            :name="item.fieldKey"
          >
            <!-- 部门 -->
            <a-tree-select
              v-if="item.fieldKey === 'department'"
              allowClear
              v-model:value="formState[item.fieldKey]"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
              :tree-data="state.departmentTree"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
              treeNodeFilterProp="title"
              :replaceFields="{
                children: 'list',
                title: 'name',
                key: 'id',
                value: 'id',
              }"
            ></a-tree-select>
            <!-- 产品线 -->
            <a-tree-select
              v-else-if="item.fieldKey === 'major'"
              allowClear
              v-model:value="formState[item.fieldKey]"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
              :tree-data="state.majorTree"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
              treeNodeFilterProp="title"
              :replaceFields="{
                children: 'list',
                title: 'name',
                key: 'id',
                value: 'id',
              }"
            ></a-tree-select>
            <a-tree-select
              v-else
              allowClear
              :labelInValue="item.defaultField === 2 ? true : false"
              v-model:value="formState[item.fieldKey]"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
              :tree-data="item.fieldOptions"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
              treeNodeFilterProp="title"
              :multiple="item.isCheck == 1"
              :treeCheckable="item.isCheck == 1"
              :showCheckedStrategy="SHOW_PARENT"
              :replaceFields="{
                children: 'children',
                title: 'optionValue',
                key: 'optionKey',
                value: 'optionKey',
              }"
            ></a-tree-select>
          </a-form-item>
          <!-- 地址 -->
          <a-form-item
            v-if="item.fieldType === 9"
            :label="item.fieldName"
            :name="item.fieldKey"
          >
            <a-cascader
              :changeOnSelect="true"
              allowClear
              :field-names="{
                label: 'name',
                value: 'name',
                children: 'list'
              }"
              v-model:value="formState[item.fieldKey]"
              :options="state.cityList"
              :placeholder="item.tipContent || `${$t('CM_Select')}${item.fieldName}`"
            />
            <div class="input-100">
              <a-textarea
                allowClear
                v-model:value="item.address"
                :maxlength="500"
                :placeholder="
                  $t('LB_QA_PleaseEnter') +
                  item.fieldName +
                  $t('charactersOrLess', [500])
                "
              />
            </div>
          </a-form-item>
        </template>
        <div class="submit-btn" v-if="!state.noShow">
          <a-button size="large" type="primary" @click="onSubmit">
            {{ state.taskType && redirectMap[state.taskType].btnText }}
          </a-button>
        </div>
      </a-form>
      </a-spin>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { validatorMobile, validatorEmail, validatorTel } from "@/utils/formRules";
import { successCallback } from "@/utils/login.js";
import { currentHost } from "@/utils/request.js";

import { getArea, login } from "@/api/user";
import { getCountry, getPosition, getMajor, getDepartment } from "@/api/other";
import { getActivityField, submitActivityField } from "@/api/activity";

import { useStore } from "vuex";
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

const props = defineProps({
  layout: {
    type: String,
    default: 'horizontal',
  },
});
const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const route = useRoute();
const store = useStore();
const state = reactive({
  taskId: Number(route.query.taskId || 0),
  customerId: Number(route.query.customerId || 0),
  reexamId: Number(route.query.reexamId || 0),
  taskType: 0,
  info: {
    taskName: '',
    startTime: 0,
    endTime: 0,
  },
  loading: false,
  fieldList: [],
  cityIndex: -1, // 字段中是否存在省市区
  tempCity: null,
  cityList: [], // 省市区数据
  countryList: [], // 国家数据
  positionList: [], // 岗位数据
  majorTree: [], // 产品线数据
  departmentTree: [], // 部门数据
  redirect: '',
  noShow: false,
});
const formRef = ref(null);
const formState = reactive({});
const rules = reactive({});
const redirectMap = {
  10: {
    url: '/exam/detail',
    title: $t('ExamInformationCollection'),
    btnText: $t('ParticipationInExams')
  },
  12: {
    url: '/questionnaire/detail',
    title: $t('ActivityInformationCollection'),
    btnText: $t('activity.participate')
  },
};

async function getDetail(fields) {
  if (fields && fields.length) {
    state.noShow = true;
    initField(fields);
    return;
  }
  if (!state.taskId) return;
  state.loading = true;
  const res = await getActivityField({ id: state.taskId, customerId: state.customerId });
  if (res.ret === 0) {
    state.info.taskName = res.data.taskName;
    state.info.startTime = res.data.startTime;
    state.info.endTime = res.data.endTime;
    state.taskType = res.data.taskType || 0;
    const detailId = res.data.stages[0].details[0].detailId;
    state.redirect = `${redirectMap[res.data.taskType].url}?id=${state.taskId}&did=${detailId}`;
    if (state.reexamId) state.redirect += `&reexamId=${state.reexamId}&taskType=23`

    // 姓名/手机号放在最前面
    const unshiftArr = [];
    let list = res.data.taskExpand.filter((item, i) => {
      if (['realName', 'mobile'].includes(item.fieldKey)) {
        unshiftArr.push(item);
        return false;
      } else {
        return true;
      }
    })
    list.unshift(...unshiftArr);
    initField(list);
  } else {
    proxy.$message.error(res.msg);
  }
  state.loading = false;
  // initField(tempList);
}
async function getCityData() {
  const res = await getArea();
  state.cityList = res.data || [];
}
async function getCountryData() {
  const res = await getCountry();
  state.countryList = res.data || [];
}
async function getPositionData() {
  const res = await getPosition({ customerId: state.customerId });
  state.positionList = res.data || [];
}
async function getMajorData() {
  const res = await getMajor({ customerId: state.customerId });
  state.majorTree = res.data || [];
}
async function getDepartmentData() {
  const res = await getDepartment({ customerId: state.customerId });
  state.departmentTree = res.data || [];
}

function initField(list = []) {
  const arr = list.filter((item, index) => {
    /**
     * defaultField 1 系统字段 2 扩展字段
     * dataRequired 1 必填 2 非必填
     * fieldType 1-输入 2-下拉 4-数字 5-日期 6-文本 7-时间 8-树
     */
    // 部分系统字段（部门/产品线）转fieldType - 8
    if (["department", "major"].includes(item.fieldKey)) item.fieldType = 8;
    // 添加必填正则
    if (item.dataRequired === 1) {
      let msg = [1, 4, 6].includes(item.fieldType) ? $t('LB_QA_PleaseEnter') : $t('CM_Select');
      rules[item.fieldKey] = [
        {
          required: item.dataRequired === 1,
          message: msg + item.fieldName,
          trigger: 'change',
        },
      ]
      // 数值
      if (item.fieldType === 4) rules[item.fieldKey][0].type = 'number';
      // 系统字段 下拉/树 type number
      if (item.defaultField === 1 && [2, 8].includes(item.fieldType)) rules[item.fieldKey][0].type = 'number';
      // 扩展字段 树分单选/多选 - 验证规则数据类型
      if (item.defaultField === 2 && item.fieldType === 8) {
        rules[item.fieldKey][0].type = item.isCheck == 1 ? 'array' : 'object';
      }
      // 扩展字段 下拉 type
      if (item.defaultField === 2 && item.fieldType === 2) {
        rules[item.fieldKey][0].type = 'object';
      }
      if (item.defaultField === 2 && item.fieldType === 9) {
        rules[item.fieldKey][0].type = 'array';
      }
    }
    // 部分系统字段添加自定义正则
    if (['mobile', 'email', 'tel'].includes(item.fieldKey )) addRules(item.fieldKey);
    /**
     * 与台添加人员保持一致
     * 省 - province 市 - city 区县 - district 合并填写 为 城市
     * 并单独保存
     */ 
    let show = true;
    if (['province', 'city', 'district'].includes(item.fieldKey)) {
      if (state.cityIndex === -1) {
        state.cityIndex = index
        state.tempCity = item;
        if (item.dataRequired === 1) {
          rules[item.fieldKey][0].type = 'array';
          rules.city2 = rules[item.fieldKey];
          delete rules[item.fieldKey];
        }
        // 省市区下拉数据
        getCityData();
      }
      show = false;
    }
    // 扩展字段 下拉数据处理
    if (item.defaultField === 2 && item.fieldType === 2) {
      item.fieldOptions.map(op => {
        op.label = op.optionValue;
        op.value = op.optionKey;
      })
    }
    // 省市区下拉数据
    if (item.fieldType === 9 && state.cityIndex === -1) getCityData()
    // 国家下拉数据
    if (item.fieldKey === 'country') getCountryData()
    // 岗位下拉数据
    if (item.fieldKey === 'position') getPositionData()
    // 产品线下拉数据
    if (item.fieldKey === 'major') getMajorData(state.customerId)
    // 部门下拉数据
    if (item.fieldKey === 'department') getDepartmentData(state.customerId)
    return show;
  });
  // 存在省市区
  if (state.cityIndex !== -1) {
    state.tempCity.fieldKey = 'city2';
    if (!state.tempCity.defaultName) state.tempCity.fieldName = $t("city");
    arr.splice(state.cityIndex, 0, state.tempCity)
  }
  state.fieldList = arr;
}

const customRules = {
  'mobile': validatorMobile,
  'email': validatorEmail,
  'tel': validatorTel,
}
function addRules(key) {
  let validator = { validator: customRules[key], trigger: 'change' }
  if (rules[key]) {
    rules[key].push(validator)
  } else {
    rules[key] = [validator]
  }
}
const systemFieldOption = {
  gender: [
    { label: $t('Pub_Man'), value: 1 }, // 男
    { label: $t('Pub_Female'), value: 2 }, // 女
  ],
  identityType: [
    { label: $t('IdCard'), value: 1 }, // 身份证
    { label: $t('passport'), value: 2 }, // 护照
    { label: $t('TaiwanPass'), value: 3 }, // 台湾通行证
    { label: $t('HongKongMacaoPass'), value: 4 }, // 港澳通行证
  ],
};

function processData() {
  let taskExpandmember = [];
  state.fieldList.map(item => {
    let fieldValue = '';
    if (item.defaultField === 2) {
      if ([2, 8].includes(item.fieldType)) {
        fieldValue = JSON.stringify(formState[item.fieldKey]) || '';
      } else if (item.fieldType === 9) {
        fieldValue = formState[item.fieldKey] ? formState[item.fieldKey].join('/') : '';
        fieldValue += (item.address ? `${fieldValue ? '/' : ''}${item.address}` : '');
      } else {
        fieldValue = (formState[item.fieldKey] || '');
      }
    } else {
      fieldValue = (formState[item.fieldKey] || '');
    }

    if (item.fieldKey === 'city2') {
      let city = '',
        province = '',
        district = '';
      if (formState[item.fieldKey]) {
        province = formState[item.fieldKey][0] || '';
        city = formState[item.fieldKey][1] || '';
        district = formState[item.fieldKey][2] || '';
      }
      taskExpandmember.push({
        fieldKey: 'city',
        fieldValue: city,
        fieldName: $t('city'),
        fieldType: item.fieldType,
      })
      taskExpandmember.push({
        fieldKey: 'province',
        fieldValue: province,
        fieldName: $t('Province'),
        fieldType: item.fieldType,
      })
      taskExpandmember.push({
        fieldKey: 'district',
        fieldValue: district,
        fieldName: $t('District'),
        fieldType: item.fieldType,
      })
    } else {
      const col = {
        fieldKey: item.fieldKey,
        fieldValue: fieldValue + '',
        fieldName: item.fieldName,
        fieldType: item.fieldType,
      }
      item.defaultName && (col.defaultName = item.defaultName);
      taskExpandmember.push(col)
    }
  })
  return taskExpandmember;
};
function onSubmit() {
  formRef.value
    .validate()
    .then(async () => {
      const params = {
        taskId: state.taskId,
        customerId: state.customerId,
        site: currentHost,
        // ...user,
        taskExpandmember: processData(),
      }
      if (state.reexamId) {
        params.reexamId = state.reexamId;
      }
      state.loading = true;
      const res = await submitActivityField(params);
      if (res.ret === 0) {
        store.dispatch("setIsExternal", true);
        res.data.changePassword = false;
        successCallback(res, state);
        // 保存成功 -- 后台临时账号创建成功 -- 使用user登录
        // user.password = proxy.$getRsaCode(res.data.password);
        // handleLogin(user);
      } else {
        proxy.$message.error(res.msg);
      }
      state.loading = false;
    })
    .catch((error) => {
      console.log('error', error);
    });
}
async function handleLogin(user) {
  const params = {
    domain: currentHost,
    platform: 1,
    ...user,
  };
  const res = await login(params);
  if (res.ret === 0) {
    store.dispatch("setIsExternal", true);
    res.data.changePassword = false;
    successCallback(res, state);
  } else {
    proxy.$message.error(res.msg);
  }
}
function getResult() {
  return new Promise(resolve => {
    formRef.value
    .validate()
    .then(() => {
      const list = processData();
      resolve(list);
    })
    .catch(() => {
      resolve([]);
    })
  })
};

getDetail();
defineExpose({
  getDetail,
  getResult,
})
</script>
<style lang="less" scoped>
.activity-bg {
  position: relative;
  height: 200px;
  background-color: #4996fe;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    height: 200px;
    background: #4996fe url("~@/assets/image/collect-bg.png") no-repeat;
    background-size: contain;
  }
  .title {
    position: absolute;
    top: 25%;
    right: 41%;
    font-size: 64px;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
.content {
  .mixinWrap(800px);
  padding-bottom: 80px;
  .detail {
    margin: 60px 0;
    text-align: center;
    .title {
      font-size: 32px;
      font-weight: 600;
    }
    .time {
      margin-top: 10px;
      color: #666;
    }
  }
  .input-100 {
    width: 100%;
    padding-top: 5px;
  }
  .submit-btn {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
